'use client';

import { Button } from '@nrdy-marketing-engine/button';
import { useShowFirstLeadForm } from '@nrdy-marketing-engine/use-show-first-lead-form';

function scrollWindowToTop() {
  setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0);
}

interface GetStartedButtonProps {
  className?: string;
  getStartedText?: string;
  target?: string;
}

export default function GetStartedButton({
  getStartedText = 'Get started',
  target,
  className,
}: GetStartedButtonProps) {
  const { showFirstLeadForm } = useShowFirstLeadForm();

  const onGetStartedClick = () => {
    showFirstLeadForm();
    scrollWindowToTop();
  };

  return (
    <Button
      className={className}
      onClick={!target && onGetStartedClick}
      target={target}
      variant="secondary"
    >
      {getStartedText}
    </Button>
  );
}
